import React, { useEffect, useRef, useState } from 'react'

import {
  Body2,
  Caption2,
  CloudinaryImage,
  TitleLarge,
  TitleMedium2,
} from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import { useRemoveGoogleTestimonial } from '@/hooks/features/useRemoveGoogleTestimonial'

import { ArrowLeft, ArrowRight } from '../../icons'
import PriorityImage from '../global/PriorityImage'
import styles from './TestimonialSlider.module.scss'

type TestimonialsAlignment = 'left' | 'center' | 'right'
type TestimonialsBackgroundColor =
  | 'cypressLight'
  | 'salamanderLight'
  | 'truffleLight'
  | 'white'
  | 'duckegg'
  | 'duckeggLight'
  | 'greyLight'
  | 'lightBlue'

type TestimonialPlatform =
  | 'google'
  | 'trustpilot'
  | 'cbsNews'
  | 'moneyGeek'
  | 'moneyCom'
type Timeout = ReturnType<typeof setTimeout>
export interface TestimonialProps {
  platform: TestimonialPlatform
  body: string
  name: string
  date: string
  image?: TestimonialPlatform extends 'other' ? string : never
  imageWidth?: TestimonialPlatform extends 'other' ? number : never
}

interface TestimonialSliderBaseProps {
  alignment: TestimonialsAlignment
  clickable: boolean
  backgroundColor: TestimonialsBackgroundColor
  testimonials: TestimonialProps[]
  mobileOptimized?: boolean
  groupImage?: string
  altText?: string
  variant?: 'finalExpense'
  experimentKey?: string
}

export interface TestimonialSliderProps {
  moduleData: TestimonialSliderBaseProps
  settings: any
}

const TestimonialSlider: React.FunctionComponent<TestimonialSliderProps> = ({
  moduleData,
  settings,
}) => {
  const { isOnVariation, testimonialsWithoutGoogle } =
    useRemoveGoogleTestimonial()
  const testimonialSliderSettings = settings['testimonial-slider.md']
  const [index, setIndex] = useState(-1)
  const timeoutRef: { current: Timeout | null } = useRef(null)
  const delay = 5000
  const testimonialList = isOnVariation
    ? testimonialsWithoutGoogle
    : moduleData.testimonials
  const { variant, mobileOptimized } = moduleData

  const CapitalizedBackgroundColor =
    moduleData.backgroundColor.charAt(0).toUpperCase() +
    moduleData.backgroundColor.slice(1)

  const testimonialSlideshowClasses = [
    styles.testimonialSlideshow,
    styles[`bg${CapitalizedBackgroundColor}`],
    styles[variant || ''],
  ]

  mobileOptimized && testimonialSlideshowClasses.push(styles.mobileOptimized)

  isOnVariation &&
    testimonialSlideshowClasses.push(styles.hideGoogleTestimonial)

  const testimonialSlideshowTabClasses = [styles.testimonialSlideshowTab]

  if (moduleData.clickable) {
    testimonialSlideshowTabClasses.push(styles.testimonialSlideshowTabHover)
  }

  const trustPilotReviewCount = () => {
    return isOnVariation
      ? testimonialSliderSettings.trustpilotReviewCountV2
      : testimonialSliderSettings.trustpilotReviewCount
  }

  function resetTimeout(): void {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    return
  }

  useEffect(() => {
    if (index < 0) {
      setIndex(0)
    }
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === testimonialList.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    )
    return (): void => {
      resetTimeout()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  const renderLogo = (testimonial: TestimonialProps) => {
    switch (testimonial.platform) {
      case 'google':
        return (
          <div className={styles.logo}>
            <CloudinaryImage
              publicId={testimonialSliderSettings.googleLogo}
              alt={'Google Review Stars'}
              crop={CloudinaryImage.CROP_METHODS.FILL}
            />
            <div className={styles.ratingAndReview}>
              <div className={styles.rating}>
                <Caption2.Medium500>
                  {testimonialSliderSettings.googleRating}
                </Caption2.Medium500>
              </div>
              <div className={styles.reviewCount}>
                <Caption2.Medium500>
                  {testimonialSliderSettings.googleReviewCount}
                </Caption2.Medium500>
              </div>
            </div>
          </div>
        )
      case 'trustpilot':
        return (
          <div className={styles.logo}>
            <CloudinaryImage
              publicId={testimonialSliderSettings.trustpilotLogo}
              alt={'TrustPilot Review Stars'}
              crop={CloudinaryImage.CROP_METHODS.FILL}
            />
            <div className={styles.ratingAndReview}>
              <Caption2.Medium500>
                {testimonialSliderSettings.trustpilotRating}
              </Caption2.Medium500>
              <Caption2.Medium500>{trustPilotReviewCount()}</Caption2.Medium500>
            </div>
          </div>
        )
      case 'cbsNews':
      case 'moneyGeek':
      case 'moneyCom': {
        const logoUrl = testimonialSliderSettings[`${testimonial.platform}Logo`]
        const logoWidth =
          testimonialSliderSettings[`${testimonial.platform}LogoWidth`]

        const style = {
          '--imageWidth': `${logoWidth}px`,
        } as React.CSSProperties

        return (
          <div className={styles.logo} style={style}>
            <CloudinaryImage
              publicId={logoUrl}
              alt={'TrustPilot Review Stars'}
              crop={CloudinaryImage.CROP_METHODS.FILL}
            />
          </div>
        )
      }
      default:
        return null
    }
  }

  const renderBodyText = (testimonial: TestimonialProps) => {
    let bodyEl = null
    switch (true) {
      case variant === 'finalExpense':
        bodyEl = (
          <div className={styles.bodyCopy}>
            &ldquo;{testimonial.body}&rdquo;
          </div>
        )
        break
      case mobileOptimized:
        bodyEl = (
          <TitleMedium2.Sans.Medium500>
            &ldquo;{testimonial.body}&rdquo;
          </TitleMedium2.Sans.Medium500>
        )
        break
      default:
        bodyEl = (
          <TitleLarge.Serif.Book500>
            &ldquo;{testimonial.body}&rdquo;
          </TitleLarge.Serif.Book500>
        )
        break
    }

    return (
      <div className={styles.bodyContent}>
        <div className={styles.body}>{bodyEl}</div>
        <div className={styles.nameAndDate}>
          {variant === 'finalExpense' ? (
            <div className={styles.name}>{testimonial.name}</div>
          ) : (
            <Body2.Medium500>{testimonial.name}</Body2.Medium500>
          )}
          <div className={styles.date}>{testimonial.date}</div>
        </div>
      </div>
    )
  }

  const handleClick = (
    idx: number,
    e: React.MouseEvent | React.KeyboardEvent
  ): void => {
    e.preventDefault()
    setIndex(idx)
  }

  const handlePreviousClick = (): void => {
    if (index > 0) {
      setIndex(index - 1)
    } else {
      setIndex(testimonialList.length - 1)
    }
  }

  const handleNextClick = (): void => {
    if (index === testimonialList.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  return (
    <div className={testimonialSlideshowClasses.join(' ')}>
      <div className={styles.innerSlideShow}>
        <div
          className={styles.testimonialSlideshowSlider}
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {testimonialList.map((testimonial, index) => (
            <div className={styles.testimonialSlide} key={index}>
              {renderLogo(testimonial)}
              {renderBodyText(testimonial)}
            </div>
          ))}
        </div>
        <div className={styles.testimonialSlideshowTabs}>
          {mobileOptimized && (
            <div
              className={cn(
                'absolute -left-8 top-0 cursor-pointer md:hidden',
                styles.arrow
              )}
              role="button"
              tabIndex={0}
              onClick={(): void => {
                handlePreviousClick()
              }}
              onKeyDown={(): void => {
                handlePreviousClick()
              }}
            >
              <ArrowLeft />
            </div>
          )}
          {testimonialList.map((_, idx) => (
            <div
              key={idx}
              className={testimonialSlideshowTabClasses.join(' ')}
              role="button"
              tabIndex={0}
              onClick={(e): void => {
                moduleData.clickable ? handleClick(idx, e) : e.preventDefault()
              }}
              onKeyDown={(e): void => {
                moduleData.clickable ? handleClick(idx, e) : e.preventDefault()
              }}
              aria-label={`Testimonial ${idx + 1}`}
            >
              <div
                className={`${styles.progressBar} ${
                  styles[`${index === idx ? 'active' : 'inactive'}`]
                }`}
              ></div>
            </div>
          ))}
          {mobileOptimized && (
            <div
              className={cn(
                'absolute -right-8 top-0 cursor-pointer md:hidden',
                styles.arrow
              )}
              role="button"
              tabIndex={0}
              onClick={(): void => {
                handleNextClick()
              }}
              onKeyDown={(): void => {
                handleNextClick()
              }}
            >
              <ArrowRight />
            </div>
          )}
        </div>
      </div>
      {mobileOptimized && moduleData.groupImage && moduleData.altText && (
        <div className={'mx-auto max-w-xs px-8 md:hidden'}>
          <PriorityImage
            publicId={moduleData.groupImage}
            alt={moduleData.altText}
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[281, 281, 0, 0]}
            width={[321, 321, 0, 0]}
          />
        </div>
      )}
    </div>
  )
}

export default TestimonialSlider
